import * as React from "react"
import { StyledHeader, TwoColumn } from "../components/Elements"
import { Link } from "../components/Link"
import { PageWrapper } from "../components/PageWrapper"
import { Section } from "../components/Section"
import { StickyInfo } from "../components/StickyInfo"

// markup
const ImprintPage = () => {
  return (
    <PageWrapper seo={{
        title: 'Data Privacy'
    }}>
        <Section spacing={['mt-2']}>
            <StyledHeader>
                <h1 className="display">
                    Data Privacy
                </h1>
                <div className="right font-size sm">
                </div>
            </StyledHeader>
        </Section>
        <Section>
            <StickyInfo left="Information" />
            <div className="grid layout-1-2">
                <div className="left">
                    <div className="sticky-title"></div>
                </div>
                <div className="right">
                    <h1>
                    What Is Google Analytics and How Does it Collect Data?
                    </h1>
                    <TwoColumn style={{ marginTop: '3rem' }}>
                        <div>
                            <Link href="https://policies.google.com/technologies/partner-sites">Google Policy</Link>
                        </div>
                        <div className="font-size md">
                        </div>
                    </TwoColumn>
                </div>
            </div>
        </Section>
    </PageWrapper>
  )
}

export default ImprintPage
